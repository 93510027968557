.page-header {
  background-color: $primary-bg-subtle;
  padding: getRem(60px) getRem(30px) getRem(30px);

  @include media-breakpoint-only(xs) {
    position: relative;
    padding: getRem(20px) getRem(15px) getRem(32px);
    margin: getRem(-20px) getRem(-5px) getRem(-20px);
  }

  @include media-breakpoint-up(sm) {
    border-radius: $border-radius;
  }

  &:after {
    @include media-breakpoint-only(xs) {
      content: "";
      position: absolute;
      bottom: 0;
      height: $border-radius-xl;
      left: 0;
      right: 0;
      background-color: $white;
      border-radius: $border-radius-xl $border-radius-xl 0 0;
      z-index: 0;
    }
  }

  &--has-tabs {
    padding-bottom: getRem(84px);

    ~ .page-container {
      padding-top: 0 !important;

      @include media-breakpoint-only(xs) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .nav {
        &[role="tablist"] {
          margin-top: getRem(-54px);

          @include media-breakpoint-only(xs) {
            margin-inline: getRem(-15px);
          }

          + .tab-content {
            padding-top: getRem(45px);

            @include media-breakpoint-only(xs) {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              padding-top: getRem(20px);
              margin-bottom: auto;
            }

            > .active {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
            }

            .tab-pane {
              > p {
                &:only-child {
                  margin: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  &--no-tabs-desktop {
    margin-bottom: getRem(8px);

    @include media-breakpoint-up(sm) {
      padding-bottom: getRem(30px);
    }

    h1 {
      &:only-child {
        margin-block-start: getRem(20px);
        margin-block-end: getRem(12px);
      }
    }

    ~ .page-container {
      @include media-breakpoint-up(sm) {
        padding-top: getRem(35px) !important;
      }

      > .nav {
        &[role="tablist"] {
          @include media-breakpoint-up(sm) {
            margin-block-start: 0;
            margin-inline: getRem(-10px);
          }

          + .tab-content {
            @include media-breakpoint-up(sm) {
              padding-block-start: getRem(16px);
            }

            .exercise {
              &__body {
                padding-block-start: 0;
              }
            }
          }

          .nav-item {
            .nav-link {
              @include media-breakpoint-up(sm) {
                margin-block: 0 !important;
                padding: 0 getRem(10px) !important;
                box-shadow: none !important;
                background-color: $body-bg;
                justify-content: flex-start;
                cursor: default;
              }
            }
          }
        }
      }

      > .tab-content {
        @include media-breakpoint-up(sm) {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          margin-inline: getRem(-10px);
        }

        .tab-pane {
          @include media-breakpoint-up(sm) {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
            max-width: 100%;
            opacity: 1;
            padding: 0 getRem(10px);
          }
        }
      }
    }
  }

  &__info-block {
    display: flex;
    gap: getRem(8px);
    align-items: flex-start;

    @include media-breakpoint-only(xs) {
      align-items: center;
    }

    :last-child {
      margin-block-end: 0;
    }

    button {
      vertical-align: unset !important;
      font-size: inherit !important;
    }
  }

  > .container {
    @include media-breakpoint-only(xs) {
      padding-inline: getRem(5px);
    }
  }

  hr {
    opacity: 1;
    color: $yellow-350;

    --bs-border-width: 1px;
  }

  .btn-primary,
  .btn-tertiary {
    @include media-breakpoint-only(xs) {
      width: 100%;
    }

    @include media-breakpoint-up(sm) {
      min-width: map-get($container-max-widths, sm);
    }
  }
}
