.accordion {
  border: none;

  .accordion-item {
    border-radius: var(--bs-accordion-border-radius);

    + .accordion-item {
      margin-block-start: getRem(5px);
    }

    .accordion-button {
      border-radius: var(--bs-accordion-border-radius);
    }
  }

  .accordion-button {
    &[aria-expanded="true"] {
      padding-bottom: getRem(8px);
    }
  }

  .accordion-body {
    padding-top: 0;
  }
}
