.table-custom {
  border: 1px solid $gray-600 !important;

  & th,
  td {
    border: none;
  }

  & td,
  th {
    padding: getRem(10px);
  }

  & tr:nth-child(odd) > * {
    background-color: $gray-100 !important;
  }

  button {
    padding: 0 !important;
  }
}
