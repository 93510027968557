html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;

  > .container {
    display: flex;
    flex-direction: column;
  }

  > .container-fluid {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    > .row {
      &:first-child {
        flex-grow: 1;
      }
    }
  }
}
