.border-radius {
  border-radius: $border-radius !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.text-cyan {
  color: $cyan !important;
}

.text-purple {
  color: $purple !important;
}

.text-pink {
  color: $pink !important;
}

.text-muted {
  color: $gray-700 !important;
}

.minh-100 {
  min-height: 100% !important;
}

.minh-100vh {
  min-height: 100vh !important;
}

.minh-0 {
  min-height: 0 !important;
}

.mw-sm {
  margin-inline: auto;

  @include media-breakpoint-up(sm) {
    max-width: getRem(map-get($container-max-widths, "sm"));
  }

  &.container,
  &.col {
    @include media-breakpoint-up(sm) {
      max-width: getRem(map-get($container-max-widths, "sm")) + $grid-gutter-width;
    }
  }
}

.bg-cyan-200 {
  background-color: $cyan-200 !important;

  .wave__top {
    path {
      fill: $pink-200;
    }
  }
}

.border-none {
  border: none;
  border-radius: 0;
}

.vertical-align-reset {
  vertical-align: unset !important;
}

.p-mb-0 {
  p {
    margin-bottom: 0;
  }
}

.p-mb-1 {
  p {
    margin-bottom: 0.5rem !important;
  }
}

.border-top-1px {
  border-top-width: 1px;
}

.fs-10 {
  font-size: getRem(10px);
}

.fs-13 {
  font-size: getRem(13px);
}

.fs-15 {
  font-size: getRem(15px);
}

.fs-16 {
  font-size: getRem(16px);
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
