.assignment-box {
  &__content {
    display: flex;
    align-items: center;
    padding: getRem(16px);
    border-radius: $border-radius-xl;
    background-color: $gray-050;

    & > *:first-child {
      flex-grow: 1;
    }

    & > * + * {
      flex-shrink: 0;
    }

    &__inclusion {
      display: flex;
      flex-direction: row;
      font-size: getRem(14px);
      color: $gray-800;

      * + * {
        margin-left: getRem(16px);
        padding-left: getRem(16px);
        border-left: 1px solid $gray-200;
      }
    }

    a {
      margin-left: auto;
      color: $gray-400 !important;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__text-clamp {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  + .assignment-box {
    margin-top: getRem(5px);
  }
}
