@use "sass:math";

.todo {
  list-style: none;
  padding-inline-start: 0;
  margin-block-end: 0;
  line-height: math.div(20, 15);

  .complete {
    color: $gray-450;
    text-decoration: line-through;

    &:before {
      border-color: $green;
      background: $green #{escape-svg(
          url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.88696 9.31288L6.15596 13.5819L16.117 3.62088' stroke='#fff' stroke-width='2' stroke-linecap='round'/></svg>")
        )} no-repeat center;
      background-size: getRem(12px);
      opacity: 0.5;
    }
  }

  li {
    position: relative;
    padding-inline-start: getRem(16px) + getRem(6px);

    &:before {
      content: "";
      position: absolute;
      width: getRem(16px);
      height: getRem(16px);
      left: 0;
      top: getRem(2px);
      border-radius: $border-radius-xs;
      border: 1px solid $gray-350;
      background-color: $white;
    }

    + li {
      margin-block-start: getRem(8px);
    }
  }

  a {
    text-decoration: none;

    &:only-child {
      position: relative;
      padding-inline-end: getRem(10px);

      &:after {
        position: absolute;
        content: "";
        height: 1em;
        right: 0;
        top: 50%;
        width: getRem(4px);
        background: transparent url("/images/icons/carret-link.svg") no-repeat center;
        transform: translateY(-50%);
      }

      &:hover {
        &:after {
          background-image: url("/images/icons/carret-link--hover.svg");
        }
      }
    }
  }
}
