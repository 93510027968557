.layout {
  &--narrow,
  &--wide {
    display: flex;
    flex-direction: column;
  }

  &--wide {
    padding-inline: 0;
    grid-template-rows: min-content auto;
  }

  &--fluid {
    display: flex;
    flex-direction: column;
    padding-bottom: getRem(20px);
    padding-inline: 0;

    @include media-breakpoint-up(sm) {
      padding: getRem(20px);
    }

    .page-header {
      @include media-breakpoint-only(xs) {
        margin-top: 0;
        margin-inline: 0;
      }
    }
  }
}
