.phone-input {
  display: flex;

  &__flag {
    margin-inline-end: getRem(4px);
    box-shadow: 0 0 1px 1px $gray-200;
  }
}

.phone-consent {
  font-size: getRem(13px);

  &__link {
    color: $gray-800;
  }
}
