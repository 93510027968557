.status-dot {
  border-radius: 50%;
  height: getRem(6px);
  width: getRem(6x);

  &.gray {
    background-color: $gray-800;
  }

  &.orange {
    background-color: $orange;
  }

  &.red {
    background-color: $red;
  }

  &.green {
    background-color: $green;
  }
}
