.btn-group-select {
  width: 100%;

  .btn-select {
    --#{$prefix}btn-color: #{$body-color};

    min-height: getRem(40px);
    //noinspection CssInvalidPropertyValue
    text-wrap: balance;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    text-wrap: initial;

    &--disabled {
      background-color: transparent;
      border-color: transparent;
      color: $black !important;
      cursor: default !important;

      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .btn-check:checked + .btn {
    &--disabled {
      background-color: $white !important;

      &:hover {
        border-color: $azure !important;
      }
    }
  }
}
