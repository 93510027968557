@use "sass:math";

$animationDelay: 0.5s;
$animationFlyDuration: 0.5;
$animationStayDuration: 0.8;
$animationTotalDuration: $animationFlyDuration * 2 + $animationStayDuration;

$imageFlickDuration: 200;

.animation-success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  width: getRem(178px);

  &.animate {
    animation: 3s $animationDelay animationSuccesFly;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: animationToggleImage #{$imageFlickDuration}ms steps(2, jump-none) 0ms infinite;

    &:nth-child(2) {
      animation-delay: #{math.div($imageFlickDuration, 2)}ms;
    }
  }
}

@keyframes animationSuccesFly {
  0% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translate(calc(-50% - 120vw), calc(-50% + 120vh));
  }

  #{percentage(math.div($animationFlyDuration, $animationTotalDuration))},
  #{percentage(math.div($animationFlyDuration + $animationStayDuration, $animationTotalDuration))} {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-in;
    transform: translate(calc(-50% + 120vw), calc(-50% - 120vh));
  }
}

@keyframes animationToggleImage {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
