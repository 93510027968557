.exercise {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: getRem(720px);
  }

  &__header {
    position: sticky;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: $body-bg;
    top: 0;
  }

  &__body {
    padding-block-start: getRem(20px);
    padding-inline: $exercise-padding-x;

    img,
    > * {
      max-width: 100%;
    }

    .exercise__body {
      padding-block-start: 0;
      padding-inline: 0;
    }
  }

  &__footer {
    bottom: 0;
    padding: getRem(20px) $exercise-padding-x;

    @include media-breakpoint-only(xs) {
      margin-top: auto;
      margin-inline: -$exercise-padding-x;
    }

    &--emphasized {
      position: relative;
      margin-top: getRem(10px);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: $border-radius-xl;
        background-color: $primary-bg-subtle;
        z-index: -1;

        @include media-breakpoint-only(xs) {
          left: 0;
          right: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  img {
    max-width: 100%;
  }
}
