.well {
  border-radius: $border-radius-lg;
  background-color: $yellow-100;

  hr {
    border-top: 1px solid $yellow-350;
    margin-block: getRem(12px);
  }

  &__btn {
    > .btn,
    > .btn-group {
      width: 100%;
      max-width: 100%;

      @include media-breakpoint-up(sm) {
        width: getRem(275px);
      }
    }
  }
}
