@import "scss/functions";

@import "scss/variables";

@media print {
  @page {
    size: A4;
    margin: 0mm;
  }

  body {
    margin: 0 20mm;
  }

  .page-header,
  .navigation {
    display: none;
  }

  .container-mw-md {
    max-width: none;
  }

  .calendar {
    page-break-inside: avoid;

    &:nth-child(even) {
      margin-top: getRem(15px);

      .calendar__print-logo {
        display: none !important;
      }
    }

    &__print-logo {
      margin-top: 20mm;
      margin-bottom: getRem(10px);
    }
  }

  .calendar-header {
    background-color: transparent;
    border-radius: 0;
    padding-top: 10px;
    border-top: 1px dashed $gray-300;
  }

  .calendar-grid {
    &__inner {
      display: flex;
      flex-direction: column;
    }
  }

  .react-calendar {
    border: 1px solid $black;

    time {
      position: absolute;
      display: block;
      width: 100%;
      text-align: center;
      bottom: getRem(10px);
      font-size: getRem(10px);
    }

    &__month-view {
      overflow: hidden;

      &__weekdays {
        border-bottom: 1px solid $black;

        &__weekday {
          print-color-adjust: exact;
          color: $gray-500;
          font-size: 0;

          abbr {
            &:after {
              font-size: getRem(9px);
              content: attr(title);
            }
          }
        }
      }

      &__days {
        width: calc(100% + 1px);
        margin-left: -1px;
        margin-bottom: -1px;

        &__day {
          position: relative;
          margin-top: -1px;

          &:last-child {
            .react-calendar__month-view__days__day__inner {
              width: calc(100% + 1px);
              border-right: 1px solid $black;
            }
          }

          &__inner {
            position: relative;
            padding-top: getRem(26px);
            padding-bottom: getRem(26px);
            border-radius: 0;
            print-color-adjust: exact;
            align-items: flex-start;

            &:after {
              content: "";
              display: block;
              position: absolute;
              inset: 0;
              border-top: 1px solid $black;
              border-bottom: 1px solid $black;
              border-left: 1px solid $black;
            }

            .label {
              top: getRem(25px);
              left: 0;
              width: 100%;
              text-align: center;
              border-radius: 0;
              transform: none;
              background-color: transparent;
              color: $body-color;
              font-size: getRem(12px);
            }

            .status-dot {
              display: none;
            }

            strong {
              position: absolute;
              top: getRem(5px);
              left: getRem(5px);
            }

            time {
              bottom: getRem(5px);
            }

            &.today {
              background-color: transparent;
              color: $body-color;
            }
          }
        }
      }
    }
  }

  .page-container,
  .col-main {
    padding: 0 !important;
  }
}
