@use "sass:math";

// Style generation settings
$enable-rfs: false;
$enable-smooth-scroll: false;

// Colors
$white: #fff;
$yellow: #fdc32f;
$orange: #f2994a;
$red: #eb5757;
$green: #27ae60;
$purple: #a75fe0;
$pink: #d53afc;
$blue: #0e6681;
$azure: #17a4c2;
$cyan: $azure;
$black: #3d3b32;

$yellow-100: #fff5eb;
$yellow-200: #fceee1;
$yellow-300: #f3e0cd;
$yellow-350: #e9e2c9;
$yellow-500: $yellow;
$yellow-700: #f3b61a;
$yellow-900: #e2ad25;

$orange-900: #e4822b;

$gray-050: #faf8f4;
$gray-100: #f3f0e9;
$gray-200: #dedcd6;
$gray-300: #cac7bf;
$gray-350: #bdbdbd;
$gray-400: #b1aea3;
$gray-450: #a6a29a;
$gray-500: #9e9b8e;
$gray-600: #8f8c82;
$gray-700: #77756c;
$gray-800: #5c594c;
$gray-900: #4f4f4f;

$cyan-100: #c5e8ea;
$cyan-200: #d7f4f5;
$cyan-300: #9edcdf;

$orange-100: #ffe8d4;

$blue-600: $blue;
$blue-900: #042f3c;

$red-300: #ffe8e8;

$green-300: #dffaea;
$green-400: #c0f1d5;
$green-700: #23a359;
$green-900: #1e924f;

$pink-200: #f8dbe8;

$light: $white;
$dark: $black;

$primary: $yellow;
$warning: $orange;

$primary-bg-subtle: $yellow-100;
$body-color: $black;

$success-text-emphasis: $body-color;
$warning-text-emphasis: $body-color;
$danger-text-emphasis: $body-color;

$danger-bg-subtle: $red-300;
$danger-border-subtle: $red;

$success-bg-subtle: $green-300;
$success-border-subtle: $green;

$warning-bg-subtle: $orange-100;
$warning-border-subtle: $orange;

$secondary-bg-subtle: $yellow-100;
$secondary-border-subtle: $yellow;

$info-bg-subtle: $cyan-300;
$info-border-subtle: $cyan;

$light-bg-subtle: $white;

$min-contrast-ratio: 2.5;

// Borders
$border-radius-xs: getRem(4px);
$border-radius-sm: getRem(10px);
$border-radius: getRem(12px);
$border-radius-lg: getRem(16px);
$border-radius-xl: getRem(20px);
$border-radius-2xl: getRem(30px);

// Btn
$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$input-btn-font-size: getRem(14px);

$btn-font-weight: 700;
$btn-padding-y: getRem(17px);
$btn-padding-x: getRem(20px);
$btn-line-height: getRem(18px);

$btn-padding-y-sm: getRem(4px);
$btn-padding-x-sm: getRem(8px);
$btn-font-size-sm: getRem(13px);

$btn-shadow: 0 getRem(1px) getRem(4px) 0 rgba(0, 0, 0, 0.1);

// Fonts
$font-family-sans-serif: "Nunito";

$headings-font-family: "Montserrat";
$headings-font-weight: 700;
$headings-line-height: math.div(28, 22);

$line-height-base: math.div(20, 15);

$font-size-root: 15px;
$font-size-base: 1rem;
$font-size-sm: getRem(14px);
$font-size-lg: $font-size-base * 1.25;

$h1-font-size: getRem(30px);
$h2-font-size: getRem(22px);
$h3-font-size: getRem(18px);
$h4-font-size: getRem(16px);
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$font-weight-lighter: 400;
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-medium: 700;
$font-weight-semibold: 700;
$font-weight-bold: 700;
$font-weight-bolder: 700;

$headings-color: $blue-900;

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: math.div($spacer, 3),
  2: math.div($spacer, 3) * 2,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
  4px: getRem(4px),
  6px: getRem(6px),
  8px: getRem(8px),
  12px: getRem(12px),
  16px: getRem(16px),
  20px: getRem(20px),
  24px: getRem(24px),
  25px: getRem(25px),
  28px: getRem(28px),
  32px: getRem(32px),
  40px: getRem(40px),
  45px: getRem(45px),
  50px: getRem(50px),
  56px: getRem(56px),
  60px: getRem(60px),
  80px: getRem(80px),
);

// Navbar & nav items
$navbar-padding-y: 0;
$navigation-width-desktop: getRem(270px);

$hr-margin-x: getRem(20px);
$hr-margin-y: getRem(20px);
$hr-color: $gray-200;

$nav-link-color: $body-color;
$nav-link-hover-color: $body-color;

$nav-link-padding-y: getRem(15px);
$nav-link-padding-x: getRem(20px);
$navbar-nav-link-padding-y: $nav-link-padding-y;
$navbar-nav-link-padding-x: $nav-link-padding-x;

// Layout
$container-max-widths: (
  sm: 330px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$grid-gutter-width: getRem(20px);

// Breadcrumbs
$breadcrumb-font-size: $font-size-sm;
$breadcrumb-margin-bottom: getRem(8px);

// Alert
$alert-border-radius: $border-radius;
$alert-padding-y: getRem(16px);
$alert-padding-x: getRem(16px);
$alert-border-width: getRem(4px);
$alert-icon-size: getRem(18px);
$alert-icon-spacing: getRem(6px);
$alert-margin-bottom: getRem(12px);

// Modal
$modal-content-border-width: 0;
$modal-backdrop-bg: $yellow-300;
$modal-backdrop-opacity: 0.5;
$modal-content-border-radius: $border-radius-xl;
$modal-inner-padding: getRem(20px);
$modal-header-padding-y: getRem(12px);
$modal-header-padding-x: getRem(15px);

$modal-md: getRem(340px);
$modal-lg: getRem(600px);

$btn-close-width: getRem(14px);
$btn-close-opacity: 1;
$btn-close-color: $black;

// Border
$border-width: 2px;
$border-width-sm: 1px;
$border-color: $gray-200;

// Forms
$btn-padding-y: getRem(16px);

$input-padding-y: getRem(14px);
$input-padding-x: getRem(15px);

$input-padding-y-lg: getRem(15px);
$input-padding-x-lg: $input-padding-x;
$input-border-radius-lg: $border-radius;
$input-line-height: getRem(22px);
$input-line-height-lg: getRem(23px);

$input-font-size: getRem(16px);
$input-font-size-lg: getRem(17px);

$input-line-height: math.div(22, 16);

$input-font-weight: $font-weight-normal;
$input-border-color: $gray-200;
$input-focus-border-color: $gray-300;
$input-focus-box-shadow: none;

$input-height-lg: calc($input-line-height-lg + $input-padding-y-lg * 2 + $border-width);

$form-label-font-weight: $font-weight-bold;
$form-switch-width: getRem(40px);
$form-switch-height: getRem(26px);
$form-switch-bg-size: getRem(30px);
$form-switch-padding-start: $form-switch-width + $grid-gutter-width;
$form-switch-color: $white;
$form-switch-focus-color: $form-switch-color;
$form-switch-checked-color: $form-switch-color;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$cyan}'/></svg>");

$form-select-padding-y: getRem(9px);
$form-select-padding-x: $input-padding-x;
$form-select-padding-x-xl: getRem(15px);
$form-select-line-height: getRem(24px);

$input-disabled-bg: $gray-100;
$input-disabled-color: $gray-300;

$form-select-indicator-color: $cyan;
$form-select-focus-box-shadow: none;
$form-select-focus-border-color: $input-focus-border-color;

// Tooltip
$tooltip-opacity: 1;

// Navbar
$navbar-height: getRem(65px);

// Progress Bar
$progress-height: getRem(5px);
$progress-bg: $yellow-100;
$progress-border-radius: 0;

// Progress Dots
$progress-dot-size: getRem(8px);
$progress-dot-gap: getRem(24px);
$progress-dot-color: $gray-400;

// Table
$table-border-width: 1px;

// Stopwatch
$stopwatch-size: getRem(120px);

// Link
$link-color: $cyan;
$link-hover-color: $blue-600;

// Exercise
$exercise-padding-x: getRem(15px);

// Accordion
$accordion-bg: $gray-050;
$accordion-border-width: 0px;

$accordion-border-radius: $border-radius;

$accordion-color: $body-color;
$accordion-button-active-bg: $accordion-bg;
$accordion-button-active-color: $accordion-color;

$accordion-transition:
  $btn-transition,
  border-radius 0.15s ease,
  padding 0.15s ease;

$accordion-padding-y: getRem(18px);
$accordion-padding-x: getRem(16px);

$accordion-button-focus-box-shadow: none;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'><path d='M15 5.40002L9.15 11.7L3.3 5.40002' stroke='#{$cyan}' stroke-width='2' stroke-linecap='round'/></svg>");
$accordion-button-active-icon: $accordion-button-icon;
