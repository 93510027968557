@use "sass:math";

.stopwatch {
  position: relative;
  margin-inline: auto;

  &__time {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: percentage(math.div(getRem(24px), $stopwatch-size));
    transform: translateX(-50%);
    text-align: center;
    font-weight: $font-weight-bold;

    time {
      font-size: getRem(30);
      line-height: math.div(32, 30);
    }

    span {
      margin-block-start: getRem(-3px);
      font-size: getRem(10px);
      line-height: math.div(12, 10);
    }
  }
}
