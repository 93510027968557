.rewards {
  position: relative;
  display: flex;
  justify-content: center;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: getRem(26px);
    height: calc(100% + #{getRem(84px)});
    top: getRem(-42px);
    transition: opacity 0.2s ease-out;
    will-change: opacity;
    opacity: 1;
  }

  &:before {
    left: 0;
    background: radial-gradient(50% 50% at 0% 50%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:after {
    right: 0;
    background: radial-gradient(50% 50% at 0% 50%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(180deg);
  }

  &--shadow-start {
    &:before {
      opacity: 0;
    }
  }

  &--shadow-end {
    &:after {
      opacity: 0;
    }
  }

  &__wrapper {
    display: flex;
    max-width: 100%;
    gap: getRem(8px);
    overflow-x: auto;
    padding-top: getRem(8px);
    padding-bottom: getRem(8px);
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    > :first-child {
      &:not(:last-child) {
        scroll-snap-align: start;
      }
    }

    > :first-child {
      &:not(:last-child) {
        scroll-snap-align: end;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: getRem(284px);
    border-radius: $border-radius-lg;
    background-color: $gray-050;
    padding: getRem(32px) getRem(40px);
    color: $body-bg;
    font-weight: $font-weight-bold;
    text-align: center;
    flex-shrink: 0;
    scroll-snap-align: center;

    &__inner {
      padding: getRem(16px) getRem(10px);
      border-radius: $border-radius-lg;
      background-color: $gray-700;
      font-size: getRem(14px);
      line-height: getRem(19px);
    }

    &__label {
      padding: getRem(5px) getRem(8px);
      border-radius: $border-radius;
      background-color: $body-color;
      color: $body-bg;
      margin: getRem(-14px) auto 0;
      font-size: getRem(13px);
      line-height: getRem(18px);
    }
  }
}
