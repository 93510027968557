.fullpage {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up(sm) {
    inset: getRem(20px);
    border-radius: $border-radius-xl;
  }

  &--split {
    justify-content: flex-start;

    .fullpage {
      &__content {
        @include media-breakpoint-only(xs) {
          padding-bottom: getRem(20px);
        }

        @include media-breakpoint-up(sm) {
          border-radius: $border-radius-xl;
          padding-bottom: getRem(40px);
        }

        .container {
          display: flex;

          .page {
            margin-block: auto;
          }
        }

        .page__content {
          @include media-breakpoint-up(sm) {
            padding-bottom: 0;
          }
        }
      }

      &__footer {
        padding: getRem(32px) calc(#{getRem(32px)} - var(--bs-gutter-x) * 0.5);

        @include media-breakpoint-only(xs) {
          margin-top: getRem(-20px);
          border-top-left-radius: $border-radius-xl;
          border-top-right-radius: $border-radius-xl;
        }

        @include media-breakpoint-up(sm) {
          padding-top: getRem(40px);
          padding-bottom: getRem(80px);
        }
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: center;
    //min-height: getRem(526px);

    @include media-breakpoint-only(xs) {
      flex-grow: 1;
    }
  }

  &__footer {
    position: relative;
    width: 100%;

    @include media-breakpoint-only(xs) {
      margin-top: auto;
      background-color: $white;
    }
  }

  .page,
  .onboarding {
    min-height: 0;
  }
}
