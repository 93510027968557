.popover-success {
  --bs-popover-header-bg: #{$green-300};
  --bs-popover-bg: #{$green-300};
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: #{$green-300};

  .close {
    position: absolute;
    appearance: none;
    width: getRem(20px);
    height: getRem(20px);
    top: getRem(12px);
    right: getRem(15px);
    border: none;
    background: transparent url("/images/icons/close.svg") no-repeat center;
  }
}
