.result-box {
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__name {
      text-wrap: nowrap;
    }
  }

  &__envelopes-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 12px;
    border-radius: 12px;
    background-color: $gray-100;
    padding: 2px 4px;
  }

  &__envelope {
    & > svg {
      width: 18px;
      height: 18px;
    }
  }
}
