.qr-block {
  display: flex;
  align-items: center;
  gap: getRem(16px);

  &__code {
    width: getRem(145px);
    height: getRem(145px);
    image-rendering: pixelated;
    object-fit: contain;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: getRem(12px);

    > p {
      margin: 0;
    }
  }
}