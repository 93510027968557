.increment-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: getRem(14px);
  border-radius: getRem(50px);
  border: $border-width solid $gray-100;
  max-width: getRem(282px);
  width: 100%;
  margin-inline: auto;
  user-select: none;

  .btn-circular {
    height: getRem(42px);
    width: getRem(42px);
    padding-inline: 0;
  }
}
