.evaluation-option {
  display: inline-flex;
  white-space: nowrap;

  &.text-warning {
    > span {
      color: $orange-900;
    }
  }
}
