.label-wrapper {
  position: relative;
  height: getRem(12px);

  .label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
