.alert {
  position: relative;
  border: none;
  overflow: hidden;
  padding-right: $alert-padding-x + $alert-border-width;

  :last-child {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: $alert-border-width;
    background-color: var(--bs-alert-border-color);
  }

  &--icon-danger {
    padding-left: $alert-padding-x + $alert-border-width + $alert-icon-size + $alert-icon-spacing;
    background-image: url("/images/icons/alert.svg");
    background-repeat: no-repeat;
    background-size: $alert-icon-size;
    background-position: $alert-padding-y $alert-padding-y;
  }

  .btn-shadow {
    min-width: getRem(74px);
  }

  .btn-emoji {
    min-width: getRem(38px);
  }
}

.alert-info {
  --bs-alert-bg: #{$cyan-200};
}
