@use "sass:math";

.form-check-label {
  font-weight: $form-label-font-weight;

  p {
    margin-bottom: 0;
  }
}

.form-switch {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: $form-switch-padding-start;

  .form-check-input {
    margin-left: 0;
    margin-right: $form-switch-padding-start * -1;
    height: $form-switch-height;
    background-color: $gray-300;
    background-size: $form-switch-bg-size;
    background-position: calc(#{math.div($form-switch-height - $form-switch-bg-size, 2)}) center;
    border: none;
    box-shadow: none !important;

    &:checked {
      background-color: $green;
      background-position: calc(100% + #{math.div($form-switch-height - $form-switch-bg-size, -2)}) center;
    }
  }
}

.form-control {
  outline: none;

  &[type="date"],
  &[type="text"] {
    padding: $form-select-padding-y $form-select-padding-x;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    font-size: getRem(15px);
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    font-weight: $font-weight-normal;
  }

  &--focus-azure {
    &:focus {
      border-color: $azure;
    }
  }
}

.form-control-lg {
  line-height: $input-line-height-lg;
  font-weight: $font-weight-bold;
  padding: $input-padding-y-lg $input-padding-x-lg !important;
}
