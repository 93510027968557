.page-title {
  &__heading {
    display: flex;
    flex-direction: row;

    & > *:first-child {
      flex-grow: 1;
    }
  }
}
