.envelope-overview {
  text-align: center;

  &__counts {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  &__envelope {
    position: relative;

    &__label {
      --eLabelBg: #{$body-color};

      display: flex;
      align-items: center;
      justify-content: center;

      width: min-content;
      padding: 2px 6px 1px;
      border-radius: getRem(10px);

      position: absolute;
      left: 20px;
      top: 6px;

      font-size: getRem(13px);
      color: $white;
      background-color: var(--eLabelBg);
      font-weight: $font-weight-bold;

      &--red {
        --eLabelBg: #{$red};
      }

      &--orange {
        --eLabelBg: #{$orange};
      }

      &--green {
        --eLabelBg: #{$green};
      }
    }

    &--grey {
      opacity: 0.3;
    }
  }
}
