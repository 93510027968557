.line-graph {
  &__wrapper {
    margin-inline: getRem(-15px);
  }

  &__popover {
    border: none;
    box-shadow: $btn-shadow;

    --bs-popover-max-width: #{getRem(300px)};

    .popover-body {
      padding: getRem(30px) getRem(24px) getRem(30px);
    }

    .popover-arrow {
      display: none;
    }
  }

  .recharts-surface {
    .recharts-xAxis {
      .recharts-cartesian-axis-ticks {
        .recharts-cartesian-axis-tick {
          + .recharts-cartesian-axis-tick {
            &:not(:last-child) {
              display: none;
            }
          }
        }
      }
    }

    .recharts-cartesian-axis-tick-value {
      fill: $body-color !important;
    }
  }

  .recharts-default-legend {
    padding: getRem(16px) 0 0;
    margin: 0;

    .recharts-legend-item {
      display: block;
      font-size: getRem(15px);
      line-height: getRem(22px);

      &:nth-last-child(n + 2) {
        margin-bottom: getRem(11px);
      }

      > svg {
        margin-right: getRem(9px);
      }
    }

    .recharts-legend-item-text {
      color: $body-color;
    }
  }

  .recharts-tooltip-cursor {
    display: none;
  }

  .recharts-line-dots {
    .recharts-dot {
      cursor: pointer;
    }
  }
}
