.offcanvas-menu {
  max-width: calc(100% - #{getRem(100px)});
  border-radius: $border-radius-xl;
  bottom: getRem(5px);

  &.offcanvas-end {
    top: getRem(5px);
    right: getRem(5px);
  }

  .btn-close {
    margin-left: auto;
  }
}
