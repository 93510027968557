.page {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    min-height: getRem(720px);
  }

  @include media-breakpoint-only(xs) {
    flex-grow: 1;
  }

  &--h-460p {
    flex-grow: 1;
    @include media-breakpoint-up(sm) {
      min-height: getRem(460px);
      flex-grow: initial;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    min-height: getRem(44px);

    .btn-close {
      margin-left: auto;
      padding: getRem(2px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-block: getRem(12px);
    flex-grow: 1;

    &--center {
      justify-content: center;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: getRem(20px) getRem(5px);

    &--has-btn {
      flex-direction: row;

      &:before {
        // Create button placehodler
        content: "";
        display: flex;
        width: getRem(54px);
      }
    }

    .btn {
      + .btn {
        margin-top: getRem(12px);
      }
    }
  }
}
