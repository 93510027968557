hr {
  &.thin {
    border-top-width: 1px;
  }

  &.yellow {
    border-top-color: $yellow-300;
  }

  &.gray-200 {
    border-top-color: $gray-200;
  }

  &.in-modal {
    margin: getRem(24px) getRem(-20px);
  }
}
