.form-check {
  padding-left: getRem(38px);

  .form-check-input,
  .form-check-label {
    cursor: pointer;
  }

  .form-check-input {
    margin-left: getRem(-38px);
  }

  .form-check-label {
    display: block;
    padding-top: getRem(7px);
  }
}

.form-check-input {
  height: getRem(26px);
  width: getRem(26px);
  background-size: getRem(28px);

  &:checked {
    background-color: $white;
    border-color: $cyan;
  }

  &:focus {
    box-shadow: 0 0 0 getRem(4px) rgba($cyan-200, 0.25);
  }
}
