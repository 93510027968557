.nav-menu {
  flex-direction: column;

  .nav-item {
    width: 100%;
    background-color: $yellow-100;
    border-radius: $border-radius;
    font-weight: $font-weight-bold;

    &:hover,
    &:focus {
      background-color: $yellow-200;

      > svg {
        [stroke="currentColor"] {
          stroke: $black;
        }

        [fill="currentColor"] {
          fill: $black;
        }
      }
    }

    &:not(:first-child) {
      margin-top: getRem(5px);
    }

    .nav-link {
      gap: 0;

      img {
        margin-left: getRem(10px);

        + img {
          margin-left: getRem(6px);
        }
      }
    }
  }

  &--results {
    .nav-item {
      background-color: $gray-050;

      &:hover,
      &:focus {
        background-color: $gray-100;
      }

      &:not(:first-child) {
        margin-top: getRem(8px);
      }
    }
  }
}
