@use "sass:math";

.modal-open {
  #root {
    filter: blur(getRem(6px));
  }
}

.modal-body {
  p {
    line-height: math.div(22, 15);
  }
}

.modal-footer {
  padding-bottom: getRem(24px);

  &--yellow-bg {
    justify-content: center;
    background-color: $yellow-100;
    padding: getRem(20px);
    @include border-top-radius($border-radius-xl);

    @include media-breakpoint-only(xs) {
      padding-inline: getRem(15px);
    }
  }
}

.modal-content {
  box-shadow: $btn-shadow;
}

.modal {
  &--exercise {
    .modal-body {
      padding-top: 0;

      .exercise {
        &__body {
          padding: 0;
        }
      }
    }
  }

  &--welcome {
    .modal-body {
      padding: 0;
    }

    .modal-footer {
      padding: getRem(20) getRem(32) getRem(40);
    }
  }
}

.modal-footer-btn-stretch {
  display: flex;
  width: 100%;
  gap: getRem(8px);
  margin-block: 0;

  > * {
    min-width: getRem(95px);
    flex-grow: 1;
  }
}

.modal-footer-btn-wrapper {
  width: 100%;
  max-width: map-get($container-max-widths, "sm");
  margin-inline: auto;

  @include media-breakpoint-up("sm") {
    max-width: getRem(300px);
  }
}
