.streaks-wrapper {
  display: flex;
  width: max-content;
}

.streak {
  $component: &;
  padding: getRem(4px) getRem(12px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(sm) {
    padding: getRem(4px) getRem(8px);
  }

  &__content {
    display: flex;
    justify-content: center;
    width: max-content;
  }

  &__count {
    font-size: getRem(20px);
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(sm) {
      font-size: getRem(30px);
    }
  }

  &__icon {
    display: block;
    width: getRem(20px);
    height: getRem(20px);

    @include media-breakpoint-up(sm) {
      width: getRem(30px);
      height: getRem(30px);
      margin-top: getRem(4px);
    }
  }

  &__title {
    width: 100%;
    text-decoration: none;
    color: $black;
    font-size: getRem(12px);
    text-align: center;
    font-weight: $font-weight-normal;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &--elixir {
    #{$component}__count {
      color: $azure;
    }
  }

  &--strawberry {
    #{$component}__count {
      color: $red;
    }
  }

  &--training {
    #{$component}__count {
      color: $gray-900;
    }
  }

  &__link {
    text-decoration: none;
  }
}

.calendar {
  .hasIcon:not(.today) {
    @include media-breakpoint-down(sm) {
      .date {
        position: relative;
        z-index: 1;
        color: #fff;
      }
      .status-text {
        position: absolute;
        top: 2px;
      }
    }
  }
}
