.wave {
  &:before,
  &:after {
    @include media-breakpoint-only(xs) {
      content: "";
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  &__top,
  &__bottom {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__top {
    top: 0;
    height: getRem(50px);
    width: 100%;

    &--pink {
      path {
        fill: $pink-200;
      }
    }
  }

  &__bottom {
    bottom: 100%;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    @include media-breakpoint-only(xs) {
      background-color: $white;
    }
  }
}
