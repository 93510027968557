.text-highlight {
  --text-highlight-width: 33px;
  --text-highlight-padding-x: 16px;
  --text-highlight-padding-top: 2px;
  --text-highlight-padding-bottom: 4px;
  --text-highlight-padding-offset: 2px;

  position: relative;
  display: inline-block;
  color: $white;
  z-index: 0;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: calc(var(--text-highlight-padding-top) * -1);
    bottom: calc(var(--text-highlight-padding-bottom) * -1);
  }

  &:after {
    --bg-color: #{$green};

    left: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba($white, 0) 0%,
      rgba($white, 0)
        calc(var(--text-highlight-width) - var(--text-highlight-padding-x) - var(--text-highlight-padding-offset)),
      var(--bg-color)
        calc(var(--text-highlight-width) - var(--text-highlight-padding-x) - var(--text-highlight-padding-offset)),
      var(--bg-color)
        calc(
          100% - (var(--text-highlight-width) - var(--text-highlight-padding-x) - var(--text-highlight-padding-offset))
        ),
      rgba($white, 0)
        calc(
          100% - (var(--text-highlight-width) - var(--text-highlight-padding-x) - var(--text-highlight-padding-offset))
        ),
      rgba($white, 0) 100%
    );
  }

  &:before {
    left: calc(var(--text-highlight-padding-x) * -1);
    right: calc(var(--text-highlight-padding-x) * -1);
    background-color: transparent;
    background-image:
      #{escape-svg(
          url("data:image/svg+xml,<svg preserveAspectRatio='none' width='33' height='34' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.199219 12.6739V13.0535C0.207664 13.5661 0.250394 14.0843 0.329323 14.6053C1.09863 19.683 2.10652 23.4922 6.84625 24.1095C8.00334 28.1371 9.76183 29.6381 12.6992 31.0362C18.4938 33.7942 32.1992 34 32.1992 34V0C30.7944 0.0542749 29.4392 0.0869431 28.1386 0.118296L28.1385 0.118297C25.8793 0.17276 23.7846 0.223257 21.8801 0.376144C18.1194 0.678031 15.0815 1.01152 12.9593 1.27337C11.8977 1.40436 11.0638 1.51759 10.4816 1.60024C10.1905 1.64158 9.96217 1.67528 9.79968 1.69976C9.71842 1.712 9.65361 1.72194 9.60561 1.72937L9.54627 1.73862L9.51524 1.74351L9.19922 1.78947C3.83044 2.64282 0.287951 7.29282 0.199219 12.6739Z' fill='#{$green}'/></svg>")
        )},
      #{escape-svg(
          url("data:image/svg+xml,<svg preserveAspectRatio='none' width='33' height='34' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M32.1992 21.3261V20.9465C32.1908 20.4339 32.148 19.9157 32.0691 19.3947C31.2998 14.317 30.2919 10.5078 25.5522 9.89049C24.3951 5.86294 22.6366 4.36193 19.6992 2.96382C13.9046 0.205761 0.199219 0 0.199219 0V34C1.60407 33.9457 2.95924 33.9131 4.25985 33.8817L4.25989 33.8817C6.51918 33.8272 8.61385 33.7767 10.5184 33.6239C14.279 33.322 17.3169 32.9885 19.4392 32.7266C20.5008 32.5956 21.3347 32.4824 21.9168 32.3998C22.208 32.3584 22.4363 32.3247 22.5988 32.3002C22.68 32.288 22.7448 32.2781 22.7928 32.2706L22.8522 32.2614L22.8832 32.2565L23.1992 32.2105C28.568 31.3572 32.1105 26.7072 32.1992 21.3261Z' fill='#{$green}'/></svg>")
        )};
    background-position: left, right;
    background-size: var(--text-highlight-width) 100%;
    background-repeat: no-repeat, no-repeat;
  }

  &--subtle {
    color: inherit;
    z-index: -1;

    &:after {
      --bg-color: #{$cyan-200};
    }

    &:before {
      background-image:
        #{escape-svg(
            url("data:image/svg+xml,<svg preserveAspectRatio='none' width='33' height='34' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.199219 12.6739V13.0535C0.207664 13.5661 0.250394 14.0843 0.329323 14.6053C1.09863 19.683 2.10652 23.4922 6.84625 24.1095C8.00334 28.1371 9.76183 29.6381 12.6992 31.0362C18.4938 33.7942 32.1992 34 32.1992 34V0C30.7944 0.0542749 29.4392 0.0869431 28.1386 0.118296L28.1385 0.118297C25.8793 0.17276 23.7846 0.223257 21.8801 0.376144C18.1194 0.678031 15.0815 1.01152 12.9593 1.27337C11.8977 1.40436 11.0638 1.51759 10.4816 1.60024C10.1905 1.64158 9.96217 1.67528 9.79968 1.69976C9.71842 1.712 9.65361 1.72194 9.60561 1.72937L9.54627 1.73862L9.51524 1.74351L9.19922 1.78947C3.83044 2.64282 0.287951 7.29282 0.199219 12.6739Z' fill='#{$cyan-200}'/></svg>")
          )},
        #{escape-svg(
            url("data:image/svg+xml,<svg preserveAspectRatio='none' width='33' height='34' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M32.1992 21.3261V20.9465C32.1908 20.4339 32.148 19.9157 32.0691 19.3947C31.2998 14.317 30.2919 10.5078 25.5522 9.89049C24.3951 5.86294 22.6366 4.36193 19.6992 2.96382C13.9046 0.205761 0.199219 0 0.199219 0V34C1.60407 33.9457 2.95924 33.9131 4.25985 33.8817L4.25989 33.8817C6.51918 33.8272 8.61385 33.7767 10.5184 33.6239C14.279 33.322 17.3169 32.9885 19.4392 32.7266C20.5008 32.5956 21.3347 32.4824 21.9168 32.3998C22.208 32.3584 22.4363 32.3247 22.5988 32.3002C22.68 32.288 22.7448 32.2781 22.7928 32.2706L22.8522 32.2614L22.8832 32.2565L23.1992 32.2105C28.568 31.3572 32.1105 26.7072 32.1992 21.3261Z' fill='#{$cyan-200}'/></svg>")
          )};
    }
  }
}

h1 {
  .text-highlight {
    --text-highlight-width: 24px;
    --text-highlight-padding-x: 6px;
  }
}
