.question {
  background-color: $yellow-100;
  border-radius: $border-radius-lg;
  padding: getRem(20px) getRem(20px) getRem(24px);

  &--thin {
    padding: getRem(14px) getRem(16px);
  }

  &--select {
    .form-check {
      + .form-check {
        padding-top: getRem(12px);
        margin-top: getRem(12px);
        border-top: $border-width-sm solid $yellow-300;
      }
    }

    .form-check-label {
      font-weight: $font-weight-normal;
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    gap: getRem(12px);

    .button-group {
      display: flex;
      align-items: center;
      gap: getRem(12px);
    }
  }

  + .question {
    margin-top: getRem(8px);
  }

  .alert {
    padding: getRem(7px) getRem(6px) getRem(7px) $alert-padding-x;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .option-selection {
    font-weight: 400;
  }
}
