.subjects {
  @include media-breakpoint-up(sm) {
    margin-inline: getRem(-2.5px) !important;
  }

  .tab-pane {
    @include media-breakpoint-up(sm) {
      padding-inline: getRem(2.5px) !important;
    }
  }

  .topic-box {
    border-radius: $border-radius;
  }
}
