.date-picker {
  @extend .form-control;

  position: relative;
  background-image: url("/images/icons/cal--blue.svg");
  background-position: calc(100% - #{getRem(15px)}) 50%;
  background-repeat: no-repeat;

  padding-right: getRem(18px) + $input-padding-x * 2;

  &:focus-within {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  input {
    &[type="text"] {
      padding: 0;
      pointer-events: none;
      background-color: transparent;
    }

    &[type="date"] {
      position: absolute;
      inset: 0;
      border: none;
      color: transparent;
      user-select: none;
      appearance: none;
      background: transparent;

      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    }
  }
}
