.achievement {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-lg;
  background-color: $gray-050;
  text-align: center;
  font-size: $font-size-sm;
  padding: getRem(16px) getRem(12px) getRem(24px);
  user-select: none;
  gap: getRem(4px);
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:hover,
  &:focus {
    background-color: $gray-100;
  }

  &--narrow {
    padding-inline: getRem(8px);
  }

  &--inactive {
    background-color: $body-bg;
    color: $gray-700;

    &:hover,
    &:focus {
      background-color: $gray-050;
    }

    > img,
    > svg {
      filter: grayscale(1);
      opacity: 0.33;
    }
  }

  > img,
  > svg {
    margin-inline: auto;
  }

  :last-child {
    margin-bottom: 0;
  }

  &__badge {
    position: absolute;
    background-color: $body-color;
    color: $body-bg;
    border-radius: $border-radius;
    font-size: getRem(13px);
    line-height: getRem(18px);
    padding: getRem(5px) getRem(8px);
    font-weight: $font-weight-bold;
    top: getRem(10px);
    right: getRem(10px);
  }
}

.col,
[class^="col-"] {
  > .achievement {
    height: 100%;
  }
}
