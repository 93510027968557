@use "sass:math";

.btn {
  &.stroke-current-color {
    svg {
      path[stroke] {
        stroke: currentColor;
      }
    }
  }

  .icon {
    &[width="20"] {
      &[height="20"] {
        margin-block: getRem(-3px);
      }
    }
  }
}

.btn-sm {
  border-radius: $border-radius;
}

.btn-smaller {
  min-height: getRem(38px) !important;
  min-width: 0 !important;
}

.btn-xl {
  width: getRem(47px);
  height: getRem(47px);
  padding: getRem(13px);

  border-radius: getRem(12px);
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  gap: getRem(4px);

  img,
  svg {
    height: getRem(18px);
  }
}

.btn-shadow {
  box-shadow: $btn-shadow;
}

.btn-circular {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: getRem(54px);
  width: getRem(54px);
  border-radius: getRem(54);

  &--sm {
    height: getRem(42px);
    width: getRem(42px);
    border-radius: getRem(42);
  }
}

.btn-primary {
  @include button-variant(
    $yellow,
    $yellow,
    $hover-background: $yellow-700,
    $hover-border: $yellow-700,
    $active-background: $yellow-900,
    $active-border: $yellow-900
  );
}

.btn-success {
  @include button-variant(
    $success,
    $success,
    $hover-background: $green-700,
    $hover-border: $green-700,
    $active-background: $green-900,
    $active-border: $green-900
  );
}

.btn-tertiary {
  @include button-variant(
    $white,
    $white,
    $hover-background: $white,
    $hover-border: $white,
    $active-background: $white,
    $active-border: $white
  );

  --bs-btn-hover-color: #{$cyan};
  --bs-btn-active-color: #{$blue};
  --bs-btn-padding-x: #{getRem(15px)};

  text-align: start;
}

.btn-light {
  @include button-variant(
    $white,
    $white,
    $hover-background: $gray-050,
    $hover-border: $gray-050,
    $active-background: $gray-100,
    $active-border: $gray-100
  );

  --bs-btn-disabled-opacity: 0.4;
}

.btn-primary,
.btn-tertiary,
.btn-success {
  &:disabled,
  &.disabled,
  fieldset:disabled & {
    background-color: $gray-200;
    border-color: $gray-200;
    color: $gray-500;
  }
}

.btn-outline-primary {
  @include button-outline-variant($black, $black, transparent, $yellow-700, $black);

  border-color: $primary;

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    border-color: $yellow-900;
  }
}

.btn-outline-success {
  @include button-outline-variant($success, $success, transparent, $gray-200, $success);

  border-color: $gray-100;

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    border-color: $gray-300;
  }
}

.btn-outline-danger {
  @include button-outline-variant($danger, $danger, transparent, $gray-200, $danger);

  border-color: $gray-100;

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    border-color: $gray-300;
  }
}

.btn-outline-secondary {
  @include button-outline-variant($black, $black, transparent, $gray-200, $black);

  border-color: $gray-100;

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    border-color: $gray-300;
  }
}

.btn-outline-secondary-darker {
  @include button-outline-variant($black, $black, transparent, $gray-200, $black);

  border-color: $gray-200;

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    border-color: $gray-300;
  }
}

.btn-outline-success,
.btn-outline-danger,
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-tertiary {
  &:disabled,
  &.disabled,
  fieldset:disabled & {
    border-color: $gray-200;
    color: $gray-500;
  }
}

.btn-select {
  font-size: getRem(13px);
  font-weight: $font-weight-normal;
  line-height: getRem(18px);
  padding: getRem(8px) getRem(4px);

  min-width: getRem(47px);
  min-height: getRem(47px);

  --#{$prefix}btn-color: #{$cyan};
  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-border-color: #{$white};
  --#{$prefix}btn-hover-color: #{$cyan};
  --#{$prefix}btn-hover-bg: #{$cyan-200};
  --#{$prefix}btn-hover-border-color: #{$cyan-200};
  --#{$prefix}btn-active-color: #{$blue};
  --#{$prefix}btn-active-bg: #{$cyan-100};
  --#{$prefix}btn-active-border-color: #{$cyan-100};
  --#{$prefix}btn-disabled-color: #{$gray-500};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{$gray-200};

  &.btn-emoji {
    min-width: getRem(45px);
  }

  .btn-check:checked + &,
  &.active {
    --#{$prefix}btn-active-bg: #{$white};
    --#{$prefix}btn-active-color: #{$body-color};
    --#{$prefix}btn-active-border-color: #{$cyan};

    font-weight: $font-weight-bold;
    background-image: url("/images/icons/check--blue.svg");
    background-repeat: no-repeat;
    background-size: getRem(10px) getRem(8px);
    background-position: calc(100% - #{getRem(4px)}) getRem(4px);
  }

  &--alert {
    min-height: getRem(38px);
  }

  &.disabled,
  &[disabled] {
    display: grid;
    opacity: 1 !important;
    box-shadow: none !important;
    padding-block: 0;
    line-height: getRem(22px);
    font-size: getRem(15px);

    background-color: var(--#{$prefix}btn-disabled-bg) !important;
    border-color: var(--#{$prefix}btn-disabled-border-color) !important;

    small {
      font-size: getRem(10px);
      line-height: 1.2;
      margin-top: getRem(-4px);
      color: $gray-700;
      font-weight: $font-weight-normal;
    }
  }
}

.btn-check {
  + .btn {
    &:hover {
      --#{$prefix}btn-color: #{$cyan};
      --#{$prefix}btn-bg: #{$cyan-200};
      --#{$prefix}btn-border-color: #{$cyan-200};
    }

    &:active {
      --#{$prefix}btn-color: #{$blue};
      --#{$prefix}btn-bg: #{$cyan-100};
      --#{$prefix}btn-border-color: #{$cyan-100};
    }
  }
}

.btn-emoji {
  height: auto;
  min-height: getRem(38px);
  font-size: getRem(17px);

  &--thin {
    min-width: getRem(38px) !important;
  }
}

.btn {
  &.text-uppercase {
    letter-spacing: math.div(0.7, 14) * 1em;
  }

  &.group-separator > * + * {
  }
}

.btn-navigation {
  position: relative;
  border: none;
  min-width: getRem(20px);
  min-height: getRem(20px);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn-arrow {
  padding-inline-end: #{$btn-padding-x * 2 + getRem(15px)};
  background-image: #{escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 16' fill='none'><path d='M5.25 3.5L9.75 8L5.25 12.5' stroke='#{$gray-400}' fill='none' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")
    )};
  background-repeat: no-repeat;
  background-size: getRem(15px);
  background-position: calc(100% - #{$btn-padding-x}) 50%;

  &.btn-tertiary {
    padding-inline-end: #{getRem(15px) * 3};
    background-position: calc(100% - #{getRem(15px)}) 50%;
  }
}

.btn-mobile-width {
  max-width: 100%;
  width: map-get($container-max-widths, "sm");

  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.btn-arrow-nav {
  min-width: getRem(47px);
  min-height: getRem(47px);
}
