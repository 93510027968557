.badge {
  padding: getRem(4px) getRem(5px) getRem(3px);
  background-color: $yellow-300;
  font-size: getRem(11px);
  line-height: getRem(15px);
  color: $body-color;
  min-width: getRem(22px);
  font-weight: $font-weight-bold;

  &.bg-success,
  &.bg-danger {
    color: $white;
  }
}

.badge-circular {
}
