.graph {
  padding: getRem(36px) getRem(40px);
  margin-block-end: getRem(8px);
  border-radius: $border-radius-lg;
  background-color: $primary-bg-subtle;
  user-select: none;

  @include media-breakpoint-only(xs) {
    padding: getRem(24px) getRem(20px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: getRem(40px);
      width: getRem(40px);
    }
  }
}
