.page-container {
  position: relative;

  @include media-breakpoint-only(xs) {
    padding-inline: getRem(15px);
  }

  .page {
    min-height: 0;
  }

  .exercise {
    &__body,
    &__footer {
      padding-inline: 0;
    }
  }
}

.container {
  .page-container {
    max-width: 100%;
  }
}
