@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
  font-weight: 400 700;
  font-style: normal italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}
