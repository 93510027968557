.envelope {
  @extend .achievement;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: getRem(18px) getRem(15px);

  &,
  &:hover {
    text-decoration: none;
  }

  &__label {
    --eLabelBg: #{$body-color};

    display: inline-block;
    height: getRem(22px);
    padding: getRem(1px) getRem(6px) 0;
    font-size: getRem(11px);
    border-radius: $border-radius-xl;
    color: $white;
    background-color: var(--eLabelBg);
    line-height: getRem(21px);
    font-weight: $font-weight-bold;

    &--red {
      --eLabelBg: #{$red};
    }

    &--orange {
      --eLabelBg: #{$orange};
    }

    &--green {
      --eLabelBg: #{$green};
    }
  }

  &__description {
    max-width: getRem(85px);
    margin-top: getRem(10px);
    margin-bottom: 0;
    font-size: getRem(14px);
    font-weight: $font-weight-bold;
    color: $gray-900;

    //noinspection CssInvalidPropertyValue
    text-wrap: balance;
  }

  > svg {
    max-width: 100%;
  }
}

.col,
[class^="col-"] {
  > .envelope {
    height: 100%;
  }
}

.envelope-count {
  display: flex;
  align-items: center;
  justify-content: center;

  width: getRem(21px);
  height: getRem(21px);
  border-radius: 50%;

  color: white;
  font-size: getRem(11px);
  line-height: getRem(11px);

  &--default {
    background-color: #2296b9;
  }
}
