@use "sass:math";

.progress-dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $progress-dot-gap;
  padding: getRem(8px) math.div($progress-dot-gap, 2);
  margin-bottom: getRem(4px);
  margin-inline: auto;

  --dot-bg: #{$progress-dot-color};
  --dot-shadow-color: transparent;

  .dot {
    position: relative;
    width: $progress-dot-size;
    height: $progress-dot-size;
    border-radius: 50%;
    background-color: var(--dot-bg);
    appearance: none;
    border: 0;
    padding: 0;

    &.current {
      box-shadow: 0 0 0 getRem(4px) var(--dot-shadow-color);

      --dot-bg: #{$black};
      --dot-shadow-color: #{$gray-200};
    }

    &.success {
      --dot-bg: #{$success};
      --dot-shadow-color: #{$green-400};
    }

    &.warning {
      --dot-bg: #{$warning};
      --dot-shadow-color: #{$yellow-200};
    }

    &.danger {
      --dot-bg: #{$danger};
      --dot-shadow-color: #{$red-300};
    }
  }
}
