.react-select-container {
  .react-select {
    line-height: $form-select-line-height;

    &__input {
      font-size: $input-font-size !important;
      line-height: $form-select-line-height !important;
    }

    &__control {
      border: $form-select-border-width solid $form-select-border-color;
      box-shadow: none !important;
      @include border-radius($form-select-border-radius, 0);
      @include transition($form-select-transition);

      &--menu-is-open {
        border-color: $form-select-focus-border-color;
      }

      &:hover,
      &--is-focused {
        border-color: $form-select-focus-border-color;
      }
    }

    &__placeholder {
      color: $gray-700;
    }

    &__value-container {
      padding: $form-select-padding-y $form-select-padding-x;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      color: $cyan;
      padding-right: $form-select-padding-x;
      padding-left: 0;

      svg {
        height: getRem(18px);
        width: getRem(18px);
      }
    }

    &__menu {
      box-shadow: none;
      border: $form-select-border-width solid $input-focus-border-color;
      @include border-radius($form-select-border-radius, 0);
      overflow: hidden;
    }

    &__menu-list {
      padding-bottom: 0;
    }

    &__option {
      color: $black;
      padding: getRem(10px) $form-select-padding-x;

      &:active {
        background-color: $gray-100;
      }

      &--is-focused {
        background-color: $gray-050;
      }

      &--is-selected {
        background-color: $gray-100;
      }
    }
  }
}

.react-select {
  &--top {
    .react-select__control {
      &--menu-is-open {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .react-select__menu {
      margin-bottom: calc($form-select-border-width * -1);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }

  &--bottom {
    .react-select__control {
      &--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .react-select__menu {
      margin-top: calc($form-select-border-width * -1);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: none;
    }
  }
}
