.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-only(xs) {
    padding: getRem(16px) getRem(10px);
    margin-top: getRem(40px);
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: getRem(80px);
    margin-top: getRem(24px);
  }
}
