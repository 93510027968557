.nav {
  &[role="tablist"] {
    flex-wrap: nowrap;

    .nav-item {
      flex-grow: 1;
      padding: 0;

      .nav-link {
        position: relative;
        padding: getRem(16px) getRem(12px);
        border-radius: $border-radius $border-radius 0 0;
        background-color: $yellow-200;
        font-weight: $font-weight-bold;
        justify-content: center;

        &.active {
          padding-top: getRem(20px);
          margin-top: getRem(-4px);
          background-color: $white;
          box-shadow: 0 getRem(1px) getRem(4px) 0 rgba($black, 0.1);

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            top: 100%;
            left: 0;
            background-color: var(--bs-body-bg);
          }
        }
      }
    }
  }
}
