.topic-box {
  display: flex;
  align-items: center;
  padding: getRem(16px);
  border-radius: $border-radius-xl;
  background-color: $gray-050;

  &[href] {
    &:hover,
    &:focus {
      background-color: $gray-100;
    }
  }

  & > *:first-child {
    flex-grow: 1;
  }

  svg {
    margin-left: auto;
    color: $gray-400 !important;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  + .topic-box {
    margin-top: getRem(5px);
  }

  &__assignments-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: getRem(22px);
  }
}
