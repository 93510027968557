.nav-link {
  border-radius: $border-radius;

  @include media-breakpoint-down(xs) {
    --bs-nav-link-padding-x: #{getRem(16px)};
  }

  &--with-arrow {
    position: relative;
    padding-right: calc(var(--bs-nav-link-padding-x) * 2 + #{getRem(15px)});

    &:hover {
      &:after {
        background-image: #{escape-svg(
            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'><path d='M5.25 3.57141L9.75 8.07141L5.25 12.5714' stroke='#{$nav-link-hover-color}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")
          )};
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: getRem(15px);
      width: getRem(15px);
      top: 50%;
      right: var(--bs-nav-link-padding-x);
      margin-top: getRem(-7.5px);
      background: transparent #{escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'><path d='M5.25 3.57141L9.75 8.07141L5.25 12.5714' stroke='#{$gray-400}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")
        )} no-repeat center;
    }
  }

  &.active {
    background-color: $yellow-200;
    font-weight: $font-weight-bold;

    svg {
      color: currentColor;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  svg {
    color: $gray-300;
  }
}
