.navbar-nav {
  hr {
    margin-inline: $hr-margin-x;
  }

  .nav-link {
    &:not(.active) {
      > svg {
        [stroke="currentColor"] {
          stroke: $gray-300;
        }

        [fill="currentColor"] {
          fill: $gray-300;
        }
      }
    }
  }
}
