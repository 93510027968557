.quote {
  padding: getRem(16px) getRem(24px);
  margin-inline: getRem(15px);
  font-style: italic;
  text-align: center;
  white-space: pre-line;
  background: transparent url("/images/bg/quote.svg") no-repeat calc(100% - #{getRem(4px)}) getRem(6px);

  > :last-child {
    margin-bottom: 0;
  }
}
