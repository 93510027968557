.exercise-chart {
  .recharts-wrapper {
    position: relative;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }

    &:before {
      height: getRem(65px);
      width: getRem(65px);
      background-color: rgba($body-color, 0.1);
    }

    &:after {
      height: getRem(47px);
      width: getRem(47px);
      background-color: $body-bg;
    }
  }
}
