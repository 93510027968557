.gained-score {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $yellow-100;
  border-radius: $border-radius-lg;
  padding: getRem(20px) getRem(20px) getRem(24px);

  font-size: getRem(15px);
  font-weight: $font-weight-bold;

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;

    width: getRem(30px);
    height: getRem(30px);

    color: $white;
    font-size: getRem(15px);
    border-radius: 50%;

    margin-left: getRem(12px);

    &.bg-danger {
      background-color: $gray-300 !important;
    }
    &.bg-warning {
      background-color: $orange !important;
    }
    &.bg-success {
      background-color: $green !important;
    }
  }
}
