@use "sass:math";

.react-calendar {
  &__month-view {
    &__weekdays {
      padding-top: getRem(12px);
      padding-bottom: getRem(12px);

      &__weekday {
        text-align: center;
        text-transform: uppercase;
        color: $gray-500;
        font-weight: $font-weight-bold;
        font-size: getRem(11px);
        line-height: math.div(15, 11);

        abbr {
          cursor: default;
          text-decoration: none;
        }
      }
    }

    &__days {
      &__day {
        padding: 0;
        appearance: none;
        border: none;
        background-color: transparent;
        overflow: visible !important;

        &:not([disabled]) {
          cursor: default;
        }

        &__inner {
          position: relative;
          display: grid;
          justify-items: center;
          align-items: flex-start;
          gap: getRem(4px);
          padding: getRem(16px) getRem(7px);
          border-radius: $border-radius;
          color: $black;
          font-weight: $font-weight-bold;
          font-size: 16px;
          height: getRem(55.5px);

          &--high {
            padding-bottom: 0;
          }

          &.today {
            color: $white;
            background-color: $blue-600;

            .status-dot {
              &.gray {
                background-color: $gray-200;
              }
            }
          }
        }

        strong {
          font-size: getRem(17px);
        }

        .label {
          position: absolute;
          top: getRem(-4px);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  time {
    display: none;
  }
}
