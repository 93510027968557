.bookmarked {
  display: grid;

  &__header {
    display: flex;
    justify-content: space-between;

    > svg {
      flex-shrink: 0;
      margin-left: getRem(8px);
    }
  }

  &__title {
  }

  > p {
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      max-width: map-get($container-max-widths, sm);
    }
  }
}
