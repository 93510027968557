.col-main {
  display: flex;
  flex-direction: column;
  padding-inline: 0;

  @include media-breakpoint-up(sm) {
    padding-inline-start: getRem(20px) + $navigation-width-desktop;
    padding-inline-end: getRem(20px);
  }
}
