@use "sass:math";

.label {
  padding: getRem(1px) getRem(4px) 0;
  border-radius: $border-radius-sm;
  font-size: getRem(8px);
  line-height: math.div(11, 8);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.label-purple {
  color: $white;
  background-color: $purple;
}

.label-pink {
  color: $white;
  background-color: $pink;
}
