.breadcrumb-item {
  a {
    color: $gray-600 !important;
    text-decoration: none !important;

    &:hover,
    &:focus {
      color: $black !important;
    }
  }

  svg {
    margin-right: getRem(3px);
  }
}
