.week-calendar {
  display: flex;

  .react-calendar {
    &__month-view {
      &__days {
        &__day {
          &__inner {
            position: relative;
            flex-basis: 0;
            flex-grow: 1;
            height: auto;
            text-align: center;
            gap: 0;

            &:not(:last-child) {
              &:after {
                content: "";
                position: absolute;
                top: getRem(13px);
                bottom: getRem(13px);
                width: 1px;
                background-color: $yellow-350;
                left: 100%;
              }
            }

            strong {
              font-size: getRem(17px);
              line-height: getRem(23px);
            }

            .label {
              position: absolute;
              left: 50%;
              top: getRem(-4px);
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}
