.steps-indicator {
  padding-top: getRem(20px);
  display: flex;
  gap: getRem(5px);
  justify-content: center;
  margin-inline: auto;

  .dot {
    height: getRem(8px);
    width: getRem(8px);
    border-radius: getRem(4px);
    background-color: $gray-300;
    transition: $transition-base;

    &.active {
      width: getRem(18px);
      background-color: $primary;
    }
  }
}
