.btn-group-emoji {
  .btn-select {
    border: none;
    height: getRem(36px);
    font-size: getRem(36px);
    line-height: 1;
    filter: grayscale(1);
    padding: 0 getRem(8px);
    transition: $transition-base;
    background: none !important;
  }

  .btn-check {
    &:checked {
      + .btn-select {
        filter: grayscale(0);
      }
    }
  }
}
