.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: getRem(8px);
  user-select: none;
  align-items: center;
  justify-content: center;

  button {
    min-width: getRem(42px);
    min-height: getRem(42px);
    padding: getRem(10px);

    &.active {
      background-color: $gray-100 !important;
      border-color: $gray-100 !important;
    }
  }

  &__ellipsis {
    font-weight: $font-weight-medium;

    + {
      .pagination__ellipsis {
        display: none;
      }
    }
  }
}