.nav-item {
  &:hover {
    .nav-link {
      &--with-arrow {
        &:after {
          background-image: #{escape-svg(
              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'><path d='M5.25 3.57141L9.75 8.07141L5.25 12.5714' stroke='#{$nav-link-hover-color}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")
            )};
        }
      }
    }
  }

  .nav-link {
    display: flex;
    gap: getRem(12px);

    &:not(.flex-column) {
      align-items: center;
    }
  }
}
