.list-options {
  display: grid;
  gap: getRem(8px);
  list-style: none;
  padding: 0;

  > li {
    padding: getRem(16px);
    border: $border-width-sm solid $border-color;
    border-radius: $border-radius-lg;
  }
}
