.tooltip-backdrop {
  position: fixed;
  z-index: $zindex-modal-backdrop;
  top: 0;
  bottom: -100vh;
  left: 0;
  right: 0;
  background-color: $yellow-300;
  opacity: 0.6;
}

.tooltip {
  position: absolute;
  background-color: $white;
  z-index: $zindex-modal;
  padding: getRem(28px) getRem(24px);
  border-radius: $border-radius-xl;
  box-shadow: 0 getRem(1px) getRem(4px) 0 rgba(0, 0, 0, 0.1);

  &__content {
    > :last-child {
      margin-bottom: 0;
    }
  }

  &--onboarding {
    max-width: calc(100vw - #{getRem(20px)});

    p {
      font-size: getRem(15px);
      line-height: getRem(22px);

      img {
        vertical-align: getRem(-4px);
      }
    }

    .btn {
      padding-block: getRem(12px);
    }
  }
}

.tooltip-carret {
  position: absolute;
  background-color: $white;
  z-index: $zindex-modal + 1;
  transform: rotate(45deg);
}

.tooltip-wrapper {
  ~ .exercise {
    &__header,
    .exercise__header {
      position: static;
      z-index: initial;
    }
  }
}

.tooltip-highlight {
  position: absolute;
  border-radius: $border-radius-lg;
  background-color: var(--bs-body-bg);
  z-index: $zindex-modal-backdrop + 1;
}
