.tag {
  padding: getRem(5px) getRem(10px);
  background-color: $white;
  border-radius: $border-radius-2xl;
  box-shadow: $btn-shadow;
}

a {
  &.tag {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}
