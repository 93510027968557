.assignment-detail-header {
  display: flex;
  align-items: center;

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    flex-grow: 1;
    min-width: 0;

    h1 {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-only(xs) {
        font-size: $h2-font-size;
      }
    }
  }
}
