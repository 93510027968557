.star-rating {
  display: flex;
}

.nav-link {
  &[role="tab"] {
    .star-rating {
      margin: getRem(-8px) auto 0;
    }
  }
}
