.btn-group-dropdown {
  z-index: 1;

  .btn {
    text-align: left;
  }

  .dropdown {
    position: static;
  }

  .dropdown-menu {
    inset: 100% 0 auto auto !important;
    width: 100%;
    padding: getRem(20px) getRem(12px) getRem(12px);
    border: none;
    background-color: $yellow;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: getRem(-12px);
    transform: none !important;
    z-index: -1;

    > .btn {
      width: 100%;
    }
  }

  .dropdown-toggle {
    padding-left: getRem(16px);
    padding-right: getRem(18px);

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(180deg);
      }
    }

    border-left: 1px solid $yellow-900;

    &:after {
      height: getRem(15px);
      width: getRem(15px);
      margin-block: getRem(-7.5px);
      border: none;
      background: transparent url("/images/icons/arr-down.svg") no-repeat center;
    }

    &.btn-primary {
      @include button-variant(
        $yellow,
        $yellow,
        $hover-background: $yellow-700,
        $hover-border: $yellow-700,
        $active-background: $yellow,
        $active-border: $yellow
      );
    }
  }
}
