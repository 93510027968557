.navigation {
  display: flex;
  top: 0;
  z-index: $zindex-fixed;
  background-color: $primary-bg-subtle;

  &.is-dashboard {
    background-color: #fff;
  }

  @include media-breakpoint-only(xs) {
    position: sticky;
    max-width: none;
    width: 100%;
    flex-basis: 100%;
    max-height: $navbar-height;
    padding: 0 getRem(17px);
    align-items: center;
  }

  @include media-breakpoint-up(sm) {
    position: fixed;
    left: 0;
    width: $navigation-width-desktop;
    bottom: 0;
    padding: getRem(40px) getRem(25px);
  }

  .navbar {
    @include media-breakpoint-only(xs) {
      height: $navbar-height;
      justify-content: space-between;
    }
  }

  &__desktop {
    width: 100%;

    @include media-breakpoint-only(xs) {
      display: none;
    }
  }

  &__mobile {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}
