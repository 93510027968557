.status-text {
  font-size: getRem(10px);
  line-height: getRem(14px);

  &.gray {
    color: $gray-800;
  }

  &.orange {
    color: $orange;
  }

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }

  .status-dot {
    display: inline-block;
    margin-right: getRem(4px) !important;
  }
}
