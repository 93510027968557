.row {
  &--border-inside {
    .col {
      + .col {
        border-left: 1px solid $border-color;
      }
    }
  }

  &--border-inside-sm-col {
    .col {
      position: relative;

      + .col {
        margin-block-start: calc(#{getRem(16px)} + 1px);

        @include media-breakpoint-only(xs) {
          border-left: none;
        }

        &:before {
          @include media-breakpoint-only(xs) {
            content: "";
            display: block;
            position: relative;
            top: getRem(-8px);
            height: 1px;
            background-color: $yellow-350;
          }
        }
      }
    }
  }
}
