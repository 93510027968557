.connection {
  display: flex;
  align-items: center;
  padding: getRem(20px);
  border-radius: $border-radius-xl;
  background-color: $gray-050;

  img,
  svg {
    &:first-child {
      margin-right: getRem(8px);
    }
  }

  a {
    margin-left: auto;
    color: $cyan !important;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  + .connection {
    margin-top: getRem(8px);
  }
}
