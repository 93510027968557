.phone-input {
  .phone-select {
    width: getRem(132px);
    font-size: getRem(16px);

    &__control {
      display: flex;
      flex-wrap: nowrap;
      border: none;
    }

    &__value {
      position: relative;
      padding-left: 16px;
      padding-right: 12px;
      height: 100%;
      border: var(--bs-border-width) solid $gray-200;
      border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
      outline: none;
      box-shadow: none;

      &:hover {
        border-color: $gray-200;
        z-index: 1;
      }

      &:active,
      &:focus {
        border-color: $gray-300;
        z-index: 1;
      }

      &:focus-within {
        border-color: $gray-300;
        z-index: 1;
      }
    }

    &__option {
      display: flex;
      align-items: center;
      padding: getRem(10px) getRem(15px);
      color: $body-color;

      &--is-focused {
        background-color: $gray-050;
      }

      &--is-selected {
        background-color: $gray-100;
      }
    }

    &__menu-list {
      padding-block: 0;
    }

    &__indicator {
      & > * {
        padding: 4px;
      }

      svg {
        fill: $cyan;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__input {
      margin-right: 0;
    }

    &__menu {
      border-radius: var(--bs-border-radius);
      border: var(--bs-border-width) solid $gray-300;
      box-shadow: none;
      overflow: hidden;
    }
  }
}
