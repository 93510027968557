.calendar-grid {
  &__header {
    position: relative;
    margin-top: getRem(-25px);
    height: getRem(42px);

    &:after {
      content: "";
      position: absolute;
      height: getRem(55px);
      width: 100%;
      background: radial-gradient(50% 24.53% at 50% 35.85%, #000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.1;
      top: 100%;
      transform: translateY(-50%);
      z-index: 0;
    }

    &__link {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $cyan !important;
      text-decoration: none;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: $white;
      z-index: 1;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__wrapper {
    overflow: hidden;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: getRem(40px);
    grid-row-gap: getRem(40px);
    transition: margin-top 0.2s ease-out;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      gap: getRem(12px);
    }

    @include media-breakpoint-up(md) {
      margin-top: 0 !important;
    }
  }
}

.calendar-header {
  padding: getRem(7px) getRem(10px);
  margin: 0;
  text-transform: capitalize;
  background-color: $gray-050;
  border-radius: $border-radius-2xl;
}

.calendar-day-big {
  &.today {
    color: #fff;

    .date {
      color: #fff;
    }
  }

  .date {
    position: relative;
    z-index: 1;
    color: #000;

    @include media-breakpoint-down(md) {
      color: #fff;
      font-size: 14px;
    }
  }
  @include media-breakpoint-down(md) {
    .status-text {
      position: absolute;
      top: 2px;
      padding-bottom: 0 !important;
      height: 45px !important;
    }
  }
}
