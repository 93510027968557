.truncate {
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  > :last-child {
    margin-bottom: 0;
  }
}
