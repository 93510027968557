.file-input {
  &:hover {
    .input-group-text {
      background-color: $gray-200;
      transition: $btn-transition;
      cursor: pointer;
    }
  }

  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }
}
