.agenda {
  display: grid;
  gap: getRem(5px);
}

.agenda-day {
  display: flex;
  justify-content: space-between;
  padding: getRem(12px) getRem(20px);
  border-radius: $border-radius-lg;
  background-color: $gray-050;
}

.agenda-day-name {
  font-size: getRem(20px);
}

.agenda-date {
  font-size: getRem(13px);
  color: $gray-700;
}

.agenda-time-set {
  background-color: $green-300 !important;
  border-radius: $border-radius-xl;
  display: flex;
  align-items: center;
  gap: getRem(4px);
  padding: getRem(4px) getRem(8px);
  font-weight: $font-weight-normal;
  border: none !important;

  svg {
    margin-left: getRem(4px);
  }
}
