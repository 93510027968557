.recharts-sector,
.recharts-pie {
  outline: none !important;
}

.recharts-label-name,
.recharts-label-value {
  fill: $gray-700;
}

.recharts-label-name {
  font-weight: $font-weight-bold;
  font-size: getRem(14px);
}

.recharts-label-value {
  font-size: getRem(13px);
}
