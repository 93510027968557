.video-wrapper {
  position: relative;
  padding: 56.25% 0 0 0;
  border-radius: $border-radius;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
