.calendar-info {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  margin: 0;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    gap: getRem(20px);
  }

  &__icon {
    width: getRem(26px);
    flex-shrink: 0;
  }
}
