.assignment-option {
  padding: getRem(16px);
  border: 1px solid $gray-200;
  border-radius: getRem(16px);

  & + & {
    margin-top: getRem(8px);
  }

  p {
    margin: 0;
  }
}
